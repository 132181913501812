import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import './SustainabilityPillar.scss'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import fbIcon from '../../assets/images/news-images/fb.png'
import twitterIcon from '../../assets/svg/twitter.svg'
import messageIcon from '../../assets/images/news-images/message.png'
import shareIcon from '../../assets/images/news-images/share.png'
import nextPage from '../../assets/images/news-images/next.png'
import backPage from '../../assets/images/news-images/back.png'
import shareWhite from '../../assets/images/shareWhite.png'
import whiteBack from '../../assets/images/icons/white-back.png'
import whiteNext from '../../assets/images/icons/white-next.png'
import Seo from '../../components/Seo/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))

export type ContentTypes = {
  title: string
  category: string
  tags?: string[]
  shortDescription: string
  subtitle: string
  seoTitle: string
  seoDescription: string
  image: {
    url: string
    description: string
    featured: string
  }
  videoLink: string
  subcategory: number
  id: string
  slug: string
  contents: {
    image: string
    video: string
    description: string
    title: string
    message: string
    type: string
  }[]
}

type PillarPropTypes = {
  data: {
    pillarData: ContentTypes
    pillarList: any
  }
}

const SustainabilityPillar = ({
  data: { pillarData, pillarList },
}: PillarPropTypes): ReactElement => {
  const [shareUrl] = useState(
    `${process.env.GATSBY_SITE_URL || 'z'}/sustainability/pillar/${
      pillarData.slug
    }/`,
  )
  const [copied, setCopied] = useState(false)
  const [nextPillar, setNextPillar] = useState('')
  const [prevPillar, setPrevPillar] = useState('')
  const [prevDisplay, setPrevDisplay] = useState('')
  const [nextDisplay, setNextDisplay] = useState('')
  const [displayIcons, setDisplayIcons] = useState(false)

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sustainabilityGallery] = useState<string[]>(
    pillarData.contents.map(
      (imageContent) => imageContent.video || imageContent.image,
    ) || [],
  )

  const sliderRef = useRef<Slider>(null)

  const handleBeforeChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex)
    },
    [],
  )

  const nextImage = useCallback(() => {
    const myScope = document.getElementById('video-container')
    const iframes = myScope?.getElementsByTagName('iframe')
    if (iframes != null) {
      for (let i = 0; i < iframes.length; i += 1) {
        const temp = iframes[i].src
        iframes[i].src = temp
      }
    }
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])

  const prevImage = useCallback(() => {
    const myScope = document.getElementById('video-container')
    const iframes = myScope?.getElementsByTagName('iframe')
    if (iframes != null) {
      for (let i = 0; i < iframes.length; i += 1) {
        const temp = iframes[i].src
        iframes[i].src = temp
      }
    }
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const goToSlide = useCallback(
    (index: number) => {
      const myScope = document.getElementById('video-container')
      const iframes = myScope?.getElementsByTagName('iframe')
      if (iframes != null) {
        for (let i = 0; i < iframes.length; i += 1) {
          const temp = iframes[i].src
          iframes[i].src = temp
        }
      }
      if (sliderRef?.current?.slickGoTo) {
        sliderRef.current.slickGoTo(index)
      }
    },
    [sliderRef],
  )

  const copyUrl = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault()

      navigator.clipboard.writeText(shareUrl)
      setCopied(true)

      setTimeout(() => {
        setCopied(false)
      }, 1500)
    },
    [shareUrl],
  )

  const openShare = useCallback((): void => {
    const newVariable: any = window.navigator

    if (newVariable && newVariable.share) {
      newVariable.share({
        url: shareUrl,
      })
    }
  }, [shareUrl])

  const getPillar = (code: string): any => {
    const pillarResult = pillarList.nodes.find(
      (pillar: any) => pillar?.subtitle === code,
    )
    return pillarResult ? pillarResult?.slug : 'sustainability'
  }

  const isImage = (url: string): boolean => {
    const imageExtensions = /(\.jpg|\.jpeg|\.png|\.PNG|\.JPEG|\.JPG)$/i
    return imageExtensions.exec(url) !== null
  }

  useEffect(() => {
    if (pillarData.subtitle === 'ca') {
      setNextDisplay('Right-Sized Living Spaces')
      setPrevDisplay('Sustainability')
      setNextPillar(getPillar('r'))
      setPrevPillar('sustainability')
    } else if (pillarData.subtitle === 'r') {
      setNextDisplay('Eco-Efficiency')
      setPrevDisplay('Connectivity and Accessibility')
      setNextPillar(getPillar('e'))
      setPrevPillar(getPillar('ca'))
    } else if (pillarData.subtitle === 'e') {
      setNextDisplay('Structural Stability')
      setPrevDisplay('Right-Sized Living Spaces')
      setNextPillar(getPillar('s'))
      setPrevPillar(getPillar('r'))
    } else if (pillarData.subtitle === 's') {
      setNextDisplay('')
      setPrevDisplay('Eco-Efficiency')
      setNextPillar('')
      setPrevPillar(getPillar('e'))
    }
  }, [pillarData]) // eslint-disable-line react-hooks/exhaustive-deps

  const tags = useState(pillarData.tags?.map((tag) => tag))

  return (
    <Layout mobile>
      <Seo
        title="Sustainability Pillars"
        jsonData={{
          keywords: JSON.stringify(tags[0]).substring(
            1,
            JSON.stringify(tags[0]).length - 1,
          ),
        }}
      />
      <div className="pillar">
        <div className="pillar-content">
          <div className="mobile-nav">
            <div className="left">
              {nextPillar !== '' ? (
                <Link
                  to={`/sustainability/pillar/${nextPillar}`}
                  className="next"
                >
                  <div>
                    <h4 className="nav-title">
                      {' '}
                      {nextPillar !== '' ? 'Next' : null}
                    </h4>
                    <h4 className="pillar-title">{nextDisplay}</h4>
                  </div>
                </Link>
              ) : (
                <Link to="/sustainability" className="next">
                  <div>
                    <h4 className="nav-title">Back</h4>
                    <h4 className="pillar-title">Sustainability</h4>
                  </div>
                </Link>
              )}
            </div>
            <div className="right">
              <button
                className="share"
                type="button"
                onClick={(): void => openShare()}
              >
                <img src={shareWhite} alt="&times;" aria-hidden />
              </button>
              {/* )} */}

              {nextPillar !== '' ? (
                <div className="next-container">
                  <Link to={`/sustainability/pillar/${nextPillar}`}>
                    <button type="button" className="next">
                      <img src={nextPage} alt="next-button" />
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="next-container">
                  <Link to="/sustainability" className="prev">
                    <button type="button" className="back">
                      <img src={nextPage} alt="back-button" />
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="pillar-content-info">
            <h1 className="title">{pillarData.contents[0]?.title}</h1>
            <h1 className="sub-title">Sustainability</h1>
            <p className="description">{pillarData.contents[0]?.description}</p>
            <div className="share">
              <h1 className="title">SHARE US</h1>
              <div className="icons">
                <FacebookShareButton url={shareUrl}>
                  <img src={fbIcon} alt="&times;" aria-hidden />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                  <img src={twitterIcon} alt="&times;" aria-hidden />
                </TwitterShareButton>
                <a href={`mailto:?body=${shareUrl}`}>
                  <img src={messageIcon} alt="&times;" aria-hidden />
                </a>
                <div className="clipboard-action">
                  <button type="button" onClick={copyUrl}>
                    <img src={shareIcon} alt="&times;" aria-hidden />
                  </button>
                  {copied && <p>Copied!</p>}
                </div>
              </div>
            </div>
            <div className="pillar-nav">
              {prevPillar === 'sustainability' ? (
                <Link to={`/${prevPillar}`} className="prev">
                  <img
                    src={backPage}
                    alt="back-button"
                    className="back-button"
                  />
                  <div>
                    <h4 className="nav-title">Previous</h4>
                    <h4 className="pillar-title">{prevDisplay}</h4>
                  </div>
                </Link>
              ) : (
                <Link
                  to={`/sustainability/pillar/${prevPillar}`}
                  className="prev"
                >
                  {prevPillar !== '' ? (
                    <img
                      src={backPage}
                      alt="back-button"
                      className="back-button"
                    />
                  ) : null}
                  <div>
                    <h4 className="nav-title">
                      {' '}
                      {prevPillar !== '' ? 'Previous' : null}
                    </h4>
                    <h4 className="pillar-title">{prevDisplay}</h4>
                  </div>
                </Link>
              )}
              <Link
                to={`/sustainability/pillar/${nextPillar}`}
                className="next"
              >
                <div>
                  <h4 className="nav-title">
                    {' '}
                    {nextPillar !== '' ? 'Next' : null}
                  </h4>
                  <h4 className="pillar-title">{nextDisplay}</h4>
                </div>
                {nextPillar !== '' ? (
                  <img
                    src={nextPage}
                    className="next-button"
                    alt="next-button"
                  />
                ) : null}
              </Link>
            </div>
          </div>

          <div className="pillar-content-image-slider" id="video-container">
            <div style={{ position: 'relative' }}>
              <Slider
                swipeToSlide={false}
                arrows={false}
                dots={false}
                beforeChange={handleBeforeChange}
                fade
                easing="ease"
                className="banner-slider"
                ref={sliderRef}
              >
                {sustainabilityGallery.map((bannerItem) => (
                  <div key={bannerItem}>
                    {isImage(bannerItem) && (
                      <>
                        <LazyLoadImage
                          alt="gallery"
                          src={bannerItem}
                          className="banner-slider"
                        />
                      </>
                    )}

                    {!isImage(bannerItem) && (
                      <>
                        <iframe
                          title="slider"
                          src={bannerItem}
                          className="banner-slider"
                        />
                      </>
                    )}
                  </div>
                ))}
              </Slider>

              <div className="nav-button-container">
                <button
                  type="button"
                  className="nav"
                  onClick={(): void => prevImage()}
                >
                  <img src={whiteBack} alt="backIcon" />
                </button>
                <button
                  type="button"
                  className="nav"
                  onClick={(): void => nextImage()}
                >
                  <img src={whiteNext} alt="nextIcon" />
                </button>
              </div>
            </div>
            <div className="nav-image-container">
              <h4>&nbsp;</h4>
              <div className="container">
                {sustainabilityGallery.map((galleryItem, index) => (
                  <button
                    type="button"
                    key={galleryItem}
                    onClick={(): void => goToSlide(index)}
                  >
                    {isImage(galleryItem) && (
                      <>
                        <img
                          alt="gallery"
                          src={galleryItem}
                          className={`slider-nav${
                            currentSlide === index ? ' active' : ''
                          } gallery-image`}
                        />
                      </>
                    )}

                    {!isImage(galleryItem) && (
                      <>
                        <iframe
                          title="gallery"
                          src={galleryItem}
                          className={`slider-nav${
                            currentSlide === index ? ' active' : ''
                          }`}
                        />
                      </>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SustainabilityPillar

export const pageQuery = graphql`
  query PillarQuery($id: String!) {
    pillarData: content(id: { eq: $id }) {
      ...SustainabilityFields
    }
    pillarList: allContent(filter: { category: { eq: "sustainability" } }) {
      nodes {
        ...SustainabilityFields
      }
    }
  }
`
